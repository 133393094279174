import Slider from '@/atoms/Slider';
import TextField from '@/atoms/TextField';
import React from 'react';
import styles from './RangeFilter.module.scss';
import { useDebounce } from 'react-use';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { formatNumber } from '@/utils';
import LargeCloseIcon from '@/icons/LargeCloseIcon';
import { Level, Toast } from '@/atoms/Toast';

type Props = {
  id: string;
  min: number;
  max: number;
  step?: number;
  fromLabel?: string;
  toLabel?: string;
  value?: { from?: number; to?: number };
  onChange: (from: number | undefined, to: number | undefined) => void;
  className?: string;
  maxLength?: number;
  unit?: string | null;
};

enum Errors {
  INVALID_YEAR = 'Ugyldigt årtal.',
  INVALID_NUMBER = 'Ugyldigt tal.',
  INVALID_AMOUNT = 'Ugyldigt beløb.',
  INVALID_MILEAGE = 'Ugyldigt kilometertal.',
  INVALID_INTERVAL = "Ugyldig interval. 'Fra' skal være mindre eller lig med 'Til'.",
}

export const RangeFilter = React.memo(
  ({
    id,
    min,
    max,
    value,
    onChange,
    fromLabel,
    toLabel,
    step = 1,
    className,
    maxLength,
    unit,
  }: Props) => {
    const isInteractingRef = React.useRef<boolean>(false);
    const [fromValue, setFromValue] = React.useState<number | undefined>(
      value?.from
    );
    const [toValue, setToValue] = React.useState<number | undefined>(value?.to);
    const [sliderValues, setSliderValues] = React.useState<
      Record<string, number | undefined>
    >({
      from: value?.from,
      to: value?.to,
      max: !!value?.to && value?.to > max ? value.to : undefined,
      min: !!value?.from && value?.from < min ? value.from : undefined
    });
    const [fromErrorText, setFromErrorText] = React.useState<
      string | undefined
    >(undefined);
    const [toErrorText, setToErrorText] = React.useState<string | undefined>(
      undefined
    );

    React.useEffect(() => {
      // Only allows the initial setting of values from outside - and then resetting to undefined below
      if (
        !isInteractingRef.current &&
        (typeof value === 'undefined' ||
          (typeof value.from === 'undefined' &&
            typeof value.to === 'undefined'))
      ) {
        // TODO: Only allow resetting when not currently interacting with the control
        setSliderValues({ from: undefined, to: undefined });
        setFromValue(undefined);
        setToValue(undefined);
      }
    }, [max, min, value]);

    useDebounce(
      () => {
        // Invalid value(s)
        if (!validate(false)) return;
        // Values did not change
        if (value?.from === fromValue && value?.to === toValue) return;
        onChange(fromValue, toValue);
      },
      400,
      [toValue, fromValue]
    );

    const handleSliderChange = (
      values: Record<string, number>,
      key?: string
    ) => {
      if (key === 'from') {
        setFromErrorText(undefined);
        setFromValue(values['from']);
      }
      if (key === 'to') {
        setToErrorText(undefined);
        setToValue(values['to']);
      }
      setSliderValues((prev) => ({ ...prev, ...values }));
    };

    const handleDragEnd = () => {
      //Fail-safe to avoid invalid intervals in the slider
      if (
        typeof fromValue !== 'undefined' &&
        typeof toValue !== 'undefined' &&
        !fromErrorText &&
        !toErrorText &&
        fromValue > toValue
      ) {
        setSliderValues((prev) => ({...prev, from: toValue, to: fromValue }));
        if (toValue && toValue < min) {
          setSliderValues((prev) => ({ ...prev, min: toValue }));
        }
        if (fromValue && fromValue > max) {
          setSliderValues((prev) => ({ ...prev, max: fromValue }));
        }
        setFromValue(toValue);
        setToValue(fromValue);
      }
    };

    // TODO: Initially split the input and slider into two states, so that we could update the slider only on blurring the input field - consider if this is overengineered and could just be collapsed into a single state
    const handleFromChange = (values: NumberFormatValues) => {
      const numericValue = Number(values.value);

      if (typeof values.value === 'undefined' || values.value === '') {
        setFromValue(undefined);
        setSliderValues((prev) => ({ ...prev, from: undefined }));
        return;
      }
      setFromValue(numericValue);
      // setSliderValues((prev) => ({ ...prev, from: numericValue }));
    };

    const handleToChange = (values: NumberFormatValues) => {
      const numericValue = Number(values.value);

      if (typeof values.value === 'undefined' || values.value === '') {
        setToValue(undefined);
        setSliderValues((prev) => ({ ...prev, to: undefined }));
        return;
      }

      setToValue(numericValue);
      // setSliderValues((prev) => ({ ...prev, to: numericValue }));
    };

    const handleBlur = () => {
      if (validate()) {
        setSliderValues({ from: fromValue, to: toValue });
        if (toValue && toValue > max) {
          setSliderValues((prev) => ({ ...prev, max: toValue }));
        }
        if (fromValue && fromValue < min) {
          setSliderValues((prev) => ({ ...prev, min: fromValue }));
        }
      } else setSliderValues({ from: undefined, to: undefined });
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.name === 'from') {
        setFromErrorText(undefined);
      } else if (e.target.name === 'to') {
        setToErrorText(undefined);
      }
    };

    const clearFrom = () => {
      setFromErrorText(undefined);
      setFromValue(undefined);
      setSliderValues((prev) => ({ ...prev, from: undefined, min: min}));
    };

    const clearTo = () => {
      setToErrorText(undefined);
      setToValue(undefined);
      setSliderValues((prev) => ({ ...prev, to: undefined, max: max }));
    };

    function validate(setError: boolean = true): boolean {
      const validateValue = {
        length: (
          minLength: number,
          maxLength: number,
          error: Errors
        ): boolean => {
          let maxLengthValid: boolean = true;
          if (typeof fromValue !== 'undefined') {
            if (
              fromValue.toString().length > maxLength ||
              fromValue.toString().length < minLength
            ) {
              setError && setFromErrorText(error);
              maxLengthValid = false;
            } else {
              setError && setFromErrorText(undefined);
            }
          }
          if (typeof toValue !== 'undefined') {
            if (
              toValue.toString().length > maxLength ||
              toValue.toString().length < minLength
            ) {
              setError && setToErrorText(error);
              maxLengthValid = false;
            } else {
              setError && setToErrorText(undefined);
            }
          }
          return maxLengthValid;
        },
        size: (minSize: number, maxSize: number, error: Errors): boolean => {
          let maxSizeValid: boolean = true;
          if (typeof fromValue !== 'undefined') {
            if (fromValue > maxSize || fromValue < minSize) {
              setError && setFromErrorText(error);
              maxSizeValid = false;
            } else {
              setError && setFromErrorText(undefined);
            }
          }
          if (typeof toValue !== 'undefined') {
            if (toValue > maxSize || toValue < minSize) {
              setError && setToErrorText(error);
              maxSizeValid = false;
            } else {
              setError && setToErrorText(undefined);
            }
          }
          return maxSizeValid;
        },
      };

      const validateInterval = (): boolean => {
        if (
          typeof fromValue !== 'undefined' &&
          typeof toValue !== 'undefined'
        ) {
          if (fromValue > toValue) {
            setError && setFromErrorText(Errors.INVALID_INTERVAL);
            setError && setToErrorText(Errors.INVALID_INTERVAL);
            return false;
          } else {
            setError && setFromErrorText(undefined);
            setError && setToErrorText(undefined);
          }
        }
        return true;
      };
      //Validating by specific types
      switch (unit) {
        case 'år':
          return (
            validateValue.length(4, 4, Errors.INVALID_YEAR) &&
            validateInterval()
          );
        case 'kr':
          return (
            validateValue.size(0, 2147483647, Errors.INVALID_AMOUNT) &&
            validateInterval()
          );
        case 'km':
          return (
            validateValue.size(0, 2147483647, Errors.INVALID_MILEAGE) &&
            validateInterval()
          );
        default:
          return (
            validateValue.length(0, maxLength || 9, Errors.INVALID_NUMBER) &&
            validateInterval()
          );
      }
    }

    return (
      <div data-e2e={id} className={className}>
        <div className={styles.range}>
          <div className={styles.control}>
            <label htmlFor={id + 'From'}>{fromLabel ?? 'Fra'}</label>
            <NumericFormat
              error={!!fromValue && !!fromErrorText}
              value={fromValue ?? ''}
              onValueChange={handleFromChange}
              allowNegative={false}
              decimalSeparator=","
              thousandSeparator={unit !== 'år' && '.'}
              decimalScale={0}
              maxLength={unit == 'år' ? 4 : maxLength}
              customInput={TextField}
              name="from"
              pattern="[0-9]*"
              inputMode="numeric"
              min={min}
              max={toValue ?? max}
              placeholder={`${unit !== 'år' ? formatNumber(min) + '' : 'Før ' + min}`}
              onBlur={handleBlur}
              onFocus={handleFocus}
              fullWidth
              noPaddingRight
              endElement={
                typeof fromValue !== 'undefined' && (
                  <button onClick={clearFrom} className={styles.resetButton}>
                    <LargeCloseIcon height={16} width={16} />
                  </button>
                )
              }
              aria-autocomplete={'none'}
              autoComplete={`off`}
            />
          </div>
          <div className={styles.divider}>-</div>
          <div className={styles.control}>
            <label htmlFor={id + 'To'}>{toLabel ?? 'Til'}</label>
            <NumericFormat
              error={!!toValue && !!toErrorText}
              value={toValue ?? ''}
              onValueChange={handleToChange}
              allowNegative={false}
              decimalSeparator=","
              thousandSeparator={unit !== 'år' && '.'}
              decimalScale={0}
              maxLength={unit == 'år' ? 4 : maxLength}
              customInput={TextField}
              name="to"
              pattern="[0-9]*"
              inputMode="numeric"
              min={min}
              placeholder={`${unit !== 'år' ? formatNumber(max) : max}+`}
              onBlur={handleBlur}
              onFocus={handleFocus}
              fullWidth
              endElement={
                typeof toValue !== 'undefined' && (
                  <button onClick={clearTo} className={styles.resetButton}>
                    <LargeCloseIcon height={16} width={16} />
                  </button>
                )
              }
              aria-autocomplete={'none'}
              autoComplete={`off`}
            />
          </div>
        </div>
        <div>
          <Slider
            disabled={!!fromErrorText || !!toErrorText}
            values={{
              from: sliderValues['from'] ?? min,
              to: sliderValues['to'] ?? max,
            }}
            min={sliderValues['min'] ?? min}
            max={sliderValues['max'] ?? max}
            step={step}
            onChange={handleSliderChange}
            onInteractStart={() => (isInteractingRef.current = true)}
            onInteractStop={() => {
              isInteractingRef.current = false;
              handleDragEnd();
            }}
          />
        </div>
        {(typeof fromValue !== 'undefined' &&
          fromErrorText &&
          (
            <div id="validationError">
              <Toast
                  level={Level.ERROR}
                  text={fromErrorText}
              />
            </div>
          )) ||
          (typeof toValue !== 'undefined' &&
            toErrorText &&
            (
              <div id="validationError">
                <Toast
                    level={Level.ERROR}
                    text={toErrorText}
                />
              </div>
            ))}
      </div>
    );
  }
);

RangeFilter.displayName = 'RangeFilter';
