import React from 'react';
import TextField from '@/atoms/TextField';
import styles from './Combobox.module.scss';
import cx from 'classnames';
import fuzzysort from 'fuzzysort';
import {
  useId,
  FloatingFocusManager,
  FloatingPortal,
} from '@floating-ui/react';
import { SmdBasSrpModelsFilterOptionValue } from 'types/api';
import LargeCloseIcon from '@/icons/LargeCloseIcon';
import { useFloatingList } from '@/hooks/useFloatingList';

// To be able to style the highlight of a searchText within the filtered options
// and to avoid using dangerouslySetInnerHTML: https://github.com/farzher/fuzzysort/issues/66#issuecomment-602167743
const highlight = (r: Fuzzysort.Result | undefined, f: any, s = '\x01') =>
  fuzzysort
    .highlight(r, s, s)!
    .split(s)
    .map((s, i) =>
      i % 2
        ? f(s, i)
        : s.replace(/^\s+|\s+$/g, (match) => {
            // for the remaining non-highlighted text, make sure to also render any potential spaces at the start & end, in the html
            return '\xa0'.repeat(match.length);
          })
    );

interface ItemProps {
  children: React.ReactNode;
  active: boolean;
}

export const Item = React.forwardRef<
  HTMLDivElement,
  ItemProps & React.HTMLProps<HTMLDivElement>
>(({ children, active, className, ...rest }, ref) => {
  const id = useId();
  return (
    <div
      ref={ref}
      role="option"
      id={id}
      aria-selected={active}
      {...rest}
      className={cx(styles.item, {
        [styles.active]: active,
        [`${className}`]: !!className,
      })}
    >
      {children}
    </div>
  );
});

Item.displayName = 'ComboboxListItem';

type ComboboxItemsProps = {
  options: Array<SmdBasSrpModelsFilterOptionValue & { level?: number }>;
  multiSelect?: boolean;
  activeIndex: number | null;
  getComboboxItemProps: (option: any, index: any) => Record<string, unknown>;
  values: (string | number)[] | undefined;
};

type FuzzySortItemsProps = ComboboxItemsProps & { searchText: string };

export const FuzzySortItems = ({
  options,
  searchText,
  multiSelect,
  activeIndex,
  getComboboxItemProps,
  values,
}: FuzzySortItemsProps) => {
  const results = React.useMemo(
    () =>
      fuzzysort.go(searchText, options!, {
        limit: 10,
        keys: ['name'],
      }),
    [searchText, options]
  );

  if (results.length === 0) {
    return (
      <div className={styles.noResults}>
        Der blev ikke fundet nogen resultater.
      </div>
    );
  }

  return (
    <>
      {results.map((option, index) => (
        // eslint-disable-next-line react/jsx-key
        <Item
          {...getComboboxItemProps(option.obj, index)}
          active={activeIndex === index}
          style={{ fontWeight: 'bold' }}
        >
          {multiSelect ? (
            <>
              <input
                type="checkbox"
                name={option.obj.value + ''}
                checked={values?.includes(option.obj.value!) ?? false}
                readOnly
              />
              <span>
                {highlight(option[0], (m: any, i: any) => (
                  <span style={{ fontWeight: 'normal' }} key={i}>
                    {m}
                  </span>
                ))}
              </span>
            </>
          ) : (
            <span>
              {highlight(option[0], (m: any, i: any) => (
                <span style={{ fontWeight: 'normal' }} key={i}>
                  {m}
                </span>
              ))}
            </span>
          )}
        </Item>
      ))}
    </>
  );
};

export const ComboboxItems = ({
  options,
  multiSelect,
  activeIndex,
  getComboboxItemProps,
  values,
}: ComboboxItemsProps) => {
  return (
    <>
      {options.map((option, index) => (
        // eslint-disable-next-line react/jsx-key
        <Item
          {...getComboboxItemProps(option, index)}
          active={activeIndex === index}
          style={{ paddingLeft: 16 + (option.level ?? 0) * 16 }}
        >
          {multiSelect ? (
            <>
              <input
                type="checkbox"
                name={option.value + ''}
                checked={values?.includes(option.value!) ?? false}
                readOnly
              />
              {option.name}
            </>
          ) : (
            option.name
          )}
        </Item>
      ))}
    </>
  );
};

type Props = {
  icon?: React.ReactNode;
  label?: string;
  values?: Array<string | number>;
  placeholder?: string;
  options: Array<SmdBasSrpModelsFilterOptionValue & { level?: number }>;
  onChange: (value: string | number) => void;
  onClear?: () => void;
  multiSelect?: boolean;
  disabled?: boolean;
  containerRef?: React.MutableRefObject<HTMLElement | null>;
  'data-e2e'?: string;
};

export const Combobox = ({
  label,
  values,
  options,
  onChange,
  onClear,
  placeholder,
  icon,
  multiSelect = true,
  disabled,
  containerRef,
  'data-e2e': e2eName,
}: Props) => {
  const [searchText, setSearchText] = React.useState('');

  const getMaxHeight = React.useCallback((availableHeight: number) => {
    const customHeight = window.innerHeight - (100 + 75 + 48); // 100px header, 75px footer, 48px dropdown input field height // TOD: might need to get these values dynamically
    return `${Math.max(
      availableHeight > customHeight ? customHeight : availableHeight,
      customHeight / 2
    )}px`;
  }, []);

  const {
    x,
    y,
    strategy,
    refs,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    activeIndex,
    setOpen,
    open,
    listRef,
  } = useFloatingList(undefined, false, getMaxHeight, disabled);

  const selectedOptions = React.useMemo(
    () =>
      values
        ?.map((value) => options.find((o) => o.value === value))
        .filter(Boolean),
    [values, options]
  );

  let textContent = '';
  if (selectedOptions && selectedOptions?.length > 0) {
    if (selectedOptions.length === options.length) {
      textContent = 'Alle';
    } else {
      textContent = selectedOptions?.map((o) => o?.name).join(', ');
    }
  }

  const handleOnChangeSearchText = (e: React.FormEvent<HTMLInputElement>) =>
    setSearchText(e.currentTarget.value);

  const handleOnFocusSearchText = (e: any) => {
    if (!open) {
      setSearchText('');
      setOpen(true);
    }
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  const getComboboxItemProps = (
    option: SmdBasSrpModelsFilterOptionValue & {
      level?: number | undefined;
    },
    index: number
  ) =>
    getItemProps({
      key: `${option.value}${index}`,
      ref(node) {
        listRef.current[index] = node;
      },
      onClick() {
        onChange(option.value!);

        if (!multiSelect) {
          setOpen(false);
          refs.domReference.current?.focus();
        }
      },
    });

  React.useEffect(() => {
    // once the combobox is disabled, clear the selected options;
    // to avoid an extra dispatch on mount, do it only if there are selected options already in the combobox
    if (
      !!onClear &&
      disabled &&
      selectedOptions &&
      selectedOptions?.length > 0
    ) {
      onClear();
    }
  }, [disabled, selectedOptions, onClear]);

  return (
    <div data-e2e={e2eName}>
      {typeof label !== 'undefined' && label.length > 0 && (
        <label className={styles.label}>{label}</label>
      )}
      <TextField
        value={open ? (searchText ? searchText : '') : textContent}
        onChange={handleOnChangeSearchText}
        onFocus={handleOnFocusSearchText}
        startElement={icon}
        disabled={disabled}
        className={cx(styles.content, {
          [styles.searchText]: open && searchText.length > 0,
        })}
        endElement={
          !!searchText &&
          open && (
            <button onClick={clearSearchText} className={styles.resetButton}>
              <LargeCloseIcon height={16} width={16} />
            </button>
          )
        }
        placeholder={placeholder}
        aria-autocomplete="list"
        wrapperProps={{
          ...getReferenceProps({
            ref: refs.setReference,
            className: cx(styles.wrapper, {
              [styles.open]: open,
            }),
          }),
        }}
      />
      {open && (
        <FloatingPortal root={containerRef}>
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                className: styles.popover,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                },
              })}
            >
              {searchText.length > 0 ? (
                <FuzzySortItems
                  searchText={searchText}
                  options={options}
                  values={values}
                  getComboboxItemProps={getComboboxItemProps}
                  multiSelect={multiSelect}
                  activeIndex={activeIndex}
                />
              ) : (
                <ComboboxItems
                  options={options}
                  values={values}
                  getComboboxItemProps={getComboboxItemProps}
                  multiSelect={multiSelect}
                  activeIndex={activeIndex}
                />
              )}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
};
