import React from 'react';
import styles from './CheckboxFilter.module.scss';
import { InfoPopover } from '../InfoPopover';
import {
  SmdBasSrpModelsFilterOptionValue,
  SmdBasSrpModelsPopOver,
} from 'types/api';

type Props = {
  options: SmdBasSrpModelsFilterOptionValue[];
  values?: Array<string | number>;
  onChange: (value: string | number) => void;
  withHeader?: boolean;
};

export const CheckboxFilter = React.memo<Props>(
  ({ options, values, onChange, withHeader = true }) => {
    let currentHeader = '#';
    return (
      <>
        {options.map((option) => {
          if (
            withHeader &&
            option.name!.charAt(0).toUpperCase() !== currentHeader
          ) {
            currentHeader = option.name!.charAt(0).toUpperCase();
            return (
              <React.Fragment key={option.name}>
                <a id={currentHeader}>
                  <h3 className={styles.header}>{currentHeader}</h3>
                </a>
                <CheckboxOption
                  name={option.name!}
                  value={option.value!}
                  checked={values?.includes(option.value!) ?? false}
                  onChange={onChange}
                />
              </React.Fragment>
            );
          }

          return (
            <CheckboxOption
              key={option.name}
              name={option.name!}
              value={option.value!}
              checked={values?.includes(option.value!) ?? false}
              onChange={onChange}
            />
          );
        })}
      </>
    );
  }
);

CheckboxFilter.displayName = 'CheckboxFilter';

type CheckboxOptionProps = {
  name: string;
  value: string | number;
  checked: boolean;
  onChange: (value: string | number) => void;
  popover?: SmdBasSrpModelsPopOver;
};

export const CheckboxOption = React.forwardRef<
  HTMLLabelElement,
  CheckboxOptionProps
>(({ name, value, checked, onChange, popover }, ref) => {
  const handleChange = () => onChange(value);

  return (
    <div>
      <label className="label" ref={ref}>
        <input
          type="checkbox"
          name={value + ''}
          checked={checked}
          onChange={handleChange}
        />
        {name}
        {!!popover && (
          <InfoPopover
            content={popover?.content ?? undefined}
            url={popover?.url ?? undefined}
          />
        )}
      </label>
    </div>
  );
});

CheckboxOption.displayName = 'CheckboxOption';
