/* eslint-disable @next/next/no-img-element */
import Button from '@/atoms/Button';
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeading,
  DialogBody,
  DialogActions,
  DialogClose,
} from '@/atoms/Dialog';
import { CheckboxFilter } from '../CheckboxFilter';
import { MultiSelectIconOption, MultiSelectIcons } from '../MultiSelectIcons';
import dialogStyles from './SelectionDialog.module.scss';
import styles from './MakeFilter.module.scss';
import { useInView } from 'react-intersection-observer';
import React from 'react';
import fuzzysort from 'fuzzysort';
import TextField from '@/atoms/TextField';
import SearchIcon from '@/icons/SearchIcon';
import {
  SmdBasSrpModelsFilterOption,
  SmdBasSrpModelsFilterOptionValue,
  SmdBasSrpModelsSearchInfo,
} from 'types/api';
import cx from 'classnames';

const defaultLetters = Array.from({ length: 26 }, (_, i) =>
  String.fromCharCode(65 + i)
);

type AlphabetNavigationProps = {
  letters?: string[];
  align?: 'left' | 'center' | 'right';
};

const AlphabetNavigation = React.forwardRef<
  HTMLDivElement,
  AlphabetNavigationProps
>(({ letters = defaultLetters, align }, ref) => {
  return (
    <div
      className={styles.letterLinks}
      ref={ref}
      style={{ justifyContent: align }}
    >
      {letters.map((letter) => (
        <a key={letter} href={`#${letter}`}>
          {letter}
        </a>
      ))}
    </div>
  );
});

AlphabetNavigation.displayName = 'AlphabetLinks';

type DialogProps = {
  children: React.ReactNode;
  topRanked: Array<SmdBasSrpModelsFilterOptionValue & { image: string }>;
  values: Array<string | number>;
  toggleValue: (key: string, value: any) => void;
  filter: SmdBasSrpModelsFilterOption;
  onClear?: () => void;
  alphabetNavigation?: boolean;
  textSearch?: boolean;
};

export const SelectionDialog = ({
  children,
  filter,
  values,
  toggleValue,
  topRanked,
  onClear,
  alphabetNavigation = true,
  textSearch = true,
}: DialogProps) => {
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = React.useState('');
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  const results =
    searchText.length > 0
      ? fuzzysort
          .go(searchText, filter.optionValues!, {
            limit: 10,
            keys: ['name', 'value'],
          })
          .map((o) => o.obj)
      : filter.optionValues;

  const handleChange = React.useCallback(
    (value: string | number) => toggleValue(filter.key!, value),
    [filter.key, toggleValue]
  );

  const availableInitials = React.useMemo(() => {
    let currentLetter = '';
    return filter.optionValues
      ?.map((v) => v.name!.toUpperCase())
      .sort()
      .reduce((acc, curr) => {
        const initialLetter = (curr + '').charAt(0);
        if (initialLetter !== currentLetter) {
          acc.push(initialLetter);
          currentLetter = initialLetter;
        }
        return acc;
      }, [] as string[]);
  }, [filter.optionValues]);

  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent
        transition={{
          initial: { transform: 'translateX(50px)' },
        }}
        className={dialogStyles.dialog}
      >
        <DialogHeading
          hideCloseIcon
          hideBackIcon={false}
          clear={onClear}
          subHeader={
            alphabetNavigation && !inView ? (
              <AlphabetNavigation
                letters={availableInitials}
                align="center"
              />
            ) : null
          }
        >
          {filter.key === 'Make' ? 'Alle mærker' : filter.title}
        </DialogHeading>
        <DialogBody className={dialogStyles.dialogBody} ref={bodyRef}>
          {textSearch && (
            <TextField
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
              startElement={
                <SearchIcon
                  style={{
                    marginLeft: 'var(--padding-inner-sm)',
                    color: 'var(--color-grey-2)',
                  }}
                />
              }
              placeholder="Søg på mærke..."
              wrapperProps={{ className: dialogStyles.search }}
            />
          )}
          {textSearch && !searchText && (
            <>
              <p className="caption">Mest populære mærker</p>
              <div className={styles.makes}>
                <CheckboxFilter
                  options={topRanked ?? []}
                  onChange={handleChange}
                  values={values}
                  withHeader={false}
                />
              </div>
            </>
          )}

          { filter.key === 'Make' &&
              <p className={cx('caption', styles.allMakesCaption)}>Alle mærker</p>
          }

          { alphabetNavigation && (
            <AlphabetNavigation ref={ref} letters={availableInitials} />
          )}
          <div className={styles.makes}>
            <CheckboxFilter
              options={results ?? []}
              onChange={handleChange}
              values={values}
              withHeader={alphabetNavigation}
            />
          </div>
        </DialogBody>
        <DialogActions>
          <DialogClose>
            <Button>Gem og gå tilbage</Button>
          </DialogClose>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
