/**
 * Consult this for ARIA: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/slider_role
 */

import React from 'react';
import styles from './Slider.module.scss';
import cx from 'classnames';
import { clamp } from '@/utils';

type Props = {
  values: Record<string, number>;
  title?: string;
  min?: number;
  max?: number;
  step?: number;
  minDistance?: number;
  onChange?: (values: Record<string, number>, key?: string) => void;
  connect?: boolean;
  onInteractStart?: () => void;
  onInteractStop?: () => void;
  disabled?: boolean;
};

export const Slider = ({
  title,
  values,
  min = 0,
  max = 100,
  step = 1,
  minDistance = step ?? 1,
  onChange,
  onInteractStart,
  onInteractStop,
  disabled,
}: Props) => {
  const range = max - min; // Absolute range e.g. -20 to 30 is 50

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.id;
    const newValue = Number(e.target.value);
    const prevValue = values[key];

    // Logic belong ensures we respect the distance between thumbs
    const otherValues = Object.keys(values)
      .filter((k) => k !== key)
      .map((k) => values[k]);

    const maxVal = Math.min(
      Math.min(...otherValues.filter((v) => v > prevValue)) - minDistance,
      max
    );
    const minVal = Math.max(
      Math.max(...otherValues.filter((v) => v < prevValue)) + minDistance,
      min
    );

    onChange?.({ ...values, [key]: clamp(newValue, minVal, maxVal) }, key);
  };

  if (
    Object.keys(values).filter((k) => typeof values[k] === 'number').length ===
    0
  ) {
    throw new Error(
      'Values must contain atleast one property with a numeric value' // TODO: Should we disable it?
    );
  }

  const valueIsSet = !!Object.keys(values).find(
    (key) => values[key] !== min && values[key] !== max
  );

  // TODO: Can be optimized and currently only work with even number of thumbs (also needs to be sorted by)
  const gradient = valueIsSet
    ? `linear-gradient(90deg, ${Object.keys(values)
        .map((key, index) =>
          index % 2
            ? `var(--color-primary) ${
                ((values[key] - min) * 100) / range
              }%, var(--color-grey-5) ${((values[key] - min) * 100) / range}%`
            : `var(--color-grey-5) ${
                ((values[key] - min) * 100) / range
              }%, var(--color-primary) ${((values[key] - min) * 100) / range}%`
        )
        .join(',')})`
    : undefined;

  return (
    <>
      <div className={styles.wrap} role="group" aria-labelledby="multi-lbl">
        <div id="multi-lbl">{title}</div>
        <div className={styles['multi-track']}>
          {Object.keys(values).map((key) => (
            <React.Fragment key={key}>
              <label className={styles.srOnly} htmlFor={key}>
                {key}
              </label>
              <input
                disabled={!!disabled}
                id={key}
                type="range"
                min={min}
                value={values[key]}
                max={max}
                step={step}
                onChange={handleChange}
                aria-valuenow={values[key]}
                className={cx({
                  [styles.hasValue]: values[key] !== min && values[key] !== max, // Show as active, if value is not min or max - might only look good with 2 sliders
                })}
                onKeyDown={onInteractStart}
                onKeyUp={onInteractStop}
              />
              <output className={styles.output}>{values[key]}</output>
            </React.Fragment>
          ))}
          <div
            className={styles['multi-track-track']}
            style={{ background: gradient }}
          />
        </div>
      </div>
    </>
  );
};

export default Slider;
