import React from 'react';
import styles from './Toggle.module.scss';
import cx from 'classnames';
import { SmdBasSrpModelsFilterOptionValue } from 'types/api';

type Props = {
  description?: string;
  options: SmdBasSrpModelsFilterOptionValue[];
  values?: Array<string | number>;
  onClick?: (value: string | number) => void;
};

const createTitleForToggle = (name: string) => {
  const indexOfParenthesis = name.indexOf('(');

  return indexOfParenthesis > 0 ? (
    <>
      {name.substring(0, indexOfParenthesis)}
      <span>{name.substring(indexOfParenthesis)}</span>
    </>
  ) : (
    name
  );
};

export const Toggle = ({ values, description, options, onClick }: Props) => {
  return (
    <div
      role="radiogroup"
      aria-describedby={description}
      className={styles.toggle}
    >
      {options.map((option) => {
        if (!option) return null;

        const title = createTitleForToggle(option.name ?? '');
        return (
          <div
            data-e2e={`toggle-${option.value}`}
            key={option.value}
            className={cx(styles.toggleOption, {
              [styles.active]: values?.includes(option.value ?? ''),
            })}
            aria-checked={values?.includes(option.value ?? 'false')}
            tabIndex={0}
            aria-label={option.name ?? undefined}
            onClick={() => !!option.value && onClick?.(option.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                !!option.value && onClick?.(option.value);
              }
            }}
          >
            {title ?? option.name}
          </div>
        );
      })}
    </div>
  );
};

Toggle.displayName = 'Toggle';
