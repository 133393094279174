import cx from 'classnames';
import styles from './FilterDescription.module.scss';
import { InfoPopover } from '@/molecules/InfoPopover';
import { SmdBasSrpModelsPopOver } from 'types/api';

type Props = {
  'data-e2e'?: string;
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  className?: string;
  popover?: SmdBasSrpModelsPopOver;
};

export const FilterDescription = ({
  'data-e2e': datae2e,
  title,
  subtitle,
  children,
  className,
  popover,
}: Props) => {
  return (
    <div data-e2e={datae2e} className={cx(styles.description, className)}>
      {typeof title !== 'undefined' && title.length > 0 && (
        <p className="font-medium spanAllColumns">
          {title}
          {!!popover && popover.placement === 'title' && (
            <InfoPopover content={popover.content ?? undefined} />
          )}
        </p>
      )}
      {typeof subtitle !== 'undefined' && subtitle.length > 0 && (
        <p className={cx('caption', styles.caption)}>
          {subtitle}
          {!!popover && popover.placement === 'subtitle' && (
            <InfoPopover
              content={popover.content ?? undefined}
              url={popover.url ?? undefined}
            />
          )}
        </p>
      )}
      {children}
    </div>
  );
};
